document.addEventListener("DOMContentLoaded", () => {
  // ------------------------------------ BURGER ------------------------------------
  document.querySelector(".burger").addEventListener('click', function () {
    this.classList.toggle("toggled");
    document.querySelector(".nav").classList.toggle("toggled");
    document.body.classList.toggle("ofHidden");
  });

  document.querySelectorAll(".nav__item").forEach(function (item) {
    item.addEventListener('click', function () {
      document.querySelector(".burger").classList.toggle("toggled");
      document.querySelector(".nav").classList.toggle("toggled");
      document.body.classList.toggle("ofHidden");
    });
  });

  // ------------------------------------ /Burger ------------------------------------


  // ------------------------------------ SMOOTHSCROLL ------------------------------------
  $(".menu__link").on('click', function (event) {
    if (this.hash !== "") {
      event.preventDefault();
      var target = document.querySelector(this.hash);
      if (target) {
        window.scrollTo({
          top: target.getBoundingClientRect().top + window.scrollY - 65,
          behavior: 'smooth'
        });
      }
    }
  });

  //------------------------------------ /SmoothScroll ------------------------------------


  // ------------------------------------ MODAL ------------------------------------
  let modal = document.getElementById("modal");
  let btns = document.querySelectorAll(".modalBtn");
  let spanArr = document.querySelectorAll(".modal__close");
  let messages = document.querySelectorAll('.modal__msg');
  let form = document.querySelector('.modal__form');
  let spinner = document.querySelector('.modal__msg.spinner');

  // Натискання на будь-яку необхідну кнопку - відкриваємо модалку
  btns.forEach(function (btn) {
    btn.onclick = function () {
      modal.classList.remove('hidden');
      form.classList.remove('hidden');
      document.body.style.overflow = "hidden";
    }
  })

  // При натисканні на <span> (x), закриваємо модальне вікно
  spanArr.forEach(span => {
    span.addEventListener("click", () => {
      modal.classList.add('hidden');
      document.body.style.overflow = "auto";

      // Скидаємо стан форми
      resetForm();
    })
  })

  // При натисканні за межами форми - закриваємо модальне вікно
  modal.addEventListener('click', function (event) {
    // Перевіряємо, чи було натиснуто за межами форми
    if (event.target === modal) {
      // Якщо так, то закриваємо модальне вікно
      modal.classList.add('hidden');
      document.body.style.overflow = "auto";

      // Скидаємо стан форми
      resetForm();
    }
  });

  function resetForm() {
    // Приховуємо всі повідомлення
    messages.forEach(msg => {
      msg.classList.add('hidden');
    });

    // Показуємо форму
    form.classList.remove('hidden');

    // Скидаємо всі поля форми
    form.reset();
  }

  // ------------------------------------ /Modal ------------------------------------


  // ------------------------------------ MAILER ------------------------------------

  form.addEventListener('submit', function (event) {
    event.preventDefault();
    let formData = new FormData(form);

    // Перевірка валідності форми
    if ($(form).valid()) {
      //приховуємо форму, і починаємо показувати спінер
      form.classList.add('hidden');
      spinner.classList.remove('hidden');

      fetch('../../../php/mailer.php', {
          method: 'POST',
          body: formData
        })
        .then(response => response.text())
        .then(result => {
          // обробка успішної відповіді
          spinner.classList.add('hidden');
          let newModal = document.querySelector('.modal__msg.success');
          newModal.classList.remove('hidden');
        })
        .catch(error => {
          // обробка помилки
          spinner.classList.add('hidden');
          let newModal = document.querySelector('.modal__msg.err');
          newModal.classList.remove('hidden');
        });
    }
  });



  // ------------------------------------ /Mailer ------------------------------------



  // ------------------------------------ FORM VALIDATION ------------------------------------

  $.validator.addMethod('phoneUA', function (value, element) {
    return this.optional(element) || /^\d{3}-\d{3}-\d{2}-\d{2}$/.test(value);
  }, 'Невірний мормат номера телефону.');

  $('#phone').on('input', function () {
    var value = this.value.replace(/\D/g, '');
    if (value.length > 3) {
      value = value.slice(0, 3) + '-' + value.slice(3);
    }
    if (value.length > 7) {
      value = value.slice(0, 7) + '-' + value.slice(7);
    }
    if (value.length > 10) {
      value = value.slice(0, 10) + '-' + value.slice(10);
    }
    if (value.length > 13) {
      value = value.slice(0, 13);
    }
    this.value = value;
  });

  // Валідація форми
  $("#modal__form").validate({
    rules: {
      phone: {
        required: true,
        phoneUA: true
      },
      name: {
        required: true
      },
      shootingType: {
        required: true
      },
      privacy: {
        required: true
      }
    },
    messages: {
      phone: {
        required: "* Це поле обов'язкове для заповнення!",
        phoneUA: "Неправильний формат номера телефону!"
      },
      name: {
        required: "* Це поле обов'язкове для заповнення!"
      },
      privacy: {
        required: "*"
      }
    }
  });

  // Заборона дефолтної відправки форми
  $("#modal__form").on("submit", function (e) {
    if (!$(this).valid()) {
      e.preventDefault();
    }
  });


  // ------------------------------------ /FORM Validation ------------------------------------


  // ------------------------------------ TABS ------------------------------------

  if (window.innerWidth > 768) {

    let tabContent = document.querySelectorAll('.tab__content');
    let tabLinks = document.querySelectorAll('.tab__link');

    //приховую всі вкладки з контентом та показую лише першу
    for (element of tabContent) {
      element.style.opacity = '0';
      element.style.zIndex = '0';
    }
    tabContent[0].style.opacity = '1';
    tabContent[0].style.zIndex = '1';

    for (element of tabLinks) {
      element.addEventListener("click", changeTab)
    }

    function changeTab(e) {
      e.preventDefault();

      console.log(tabContent); //clg

      // по дефолту знімаю з усіх табів клас активності та даю його тому табу, на який здійснили клік
      for (element of tabLinks) {
        element.classList.remove('tab__link_active');

        e.target.classList.add('tab__link_active');
      }

      // по дефолту приховую всі вкладки з контентом і показую той, який відповідає активному табу
      for (element of tabContent) {
        // element.style.display = 'none';
        element.style.opacity = '0';
        element.style.zIndex = '0';
        console.log(element.style.zIndex);

        if (e.target.dataset.tabid === element.id) {
          // element.style.display = 'flex';
          element.style.opacity = '1';
          element.style.zIndex = '1';
          console.log(element.style.zIndex);
        }

      }
    }
  }

  // ------------------------------------ /Tabs ------------------------------------



  // ------------------------------------ VIDEO LOOP BY HOVER ------------------------------------

  let videos = document.querySelectorAll(".video__additional");
  videos.forEach((vid) => {
    vid.addEventListener("mouseover", () => {
      vid.play();
    });
    vid.addEventListener("mouseout", () => {
      vid.pause();
    });
  });

  // ------------------------------------ /Video loop by hover ------------------------------------

})